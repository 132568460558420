import { useState, createContext } from 'react';

export interface IPageContext {
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
  sessionName: string;
  setSessionName: React.Dispatch<React.SetStateAction<string>>;
  user: any
  sessionData: Object|any;
  setSessionData: React.Dispatch<React.SetStateAction<Object|any>>;
}

const PageContext = createContext<IPageContext>({} as IPageContext);

const PageProvider: React.FC<any> = ({ children }: any) => {
  const [isLogged, setIsLogged] = useState(false);
  const [sessionName, setSessionName] = useState('');
  const [sessionData, setSessionData] = useState({});

  // useEffect(() => {
  //   const load = async () => {
  //     const resquest = await authService.showMenu();
  //     setMenu(resquest);
  //   };
  //   if (isLogged) {
  //     load();
  //   }
  // }, [isLogged]);

  return (
    <PageContext.Provider
      value={{
        isLogged,
        setIsLogged,
        sessionName,
        setSessionName,
        user: {
          student: {
            name: 'João',
          }
        },
        sessionData,
        setSessionData
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
export { PageContext };

import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePageContext } from '../../hooks';
import Header from '../../components/_ui/Header';
import { Container, Section } from './styles';
import Menu from '../../components/_ui/Menu';

const RequiredAuth: React.FC<any> = ({ children }): any => {
    const { isLogged } = usePageContext();

    if (!isLogged) {
        return <Navigate to="/" replace />;
    }

    return (
        <Container>
            {/* <Menu /> */}
            {/* <Section> */}
                {/* <Header /> */}
                {children}
            {/* </Section> */}
        </Container>
    );
};

export default RequiredAuth;

import React, { createContext, useContext } from 'react';
import { useSocket } from '../hooks/useSocket'; // Importa o hook que criamos

const SocketContext = createContext<any>(null);

export const useSocketContext = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const socket = useSocket('https://api.whatsapp.aviait.com.br');

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

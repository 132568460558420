import React from 'react';
import GlobalStyles from './styles/globalStyles';
// import Page from './pages/Login';
// import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import PageProvider from './context/PageContext';
import Routes from './routes';
import { SocketProvider } from './context/SocketContext';

function App() {
  return (
    <BrowserRouter>
      <SocketProvider>
        <PageProvider>
          <GlobalStyles />
          <ToastContainer autoClose={5000} />
          <Routes />
        </PageProvider>
      </SocketProvider>
    </BrowserRouter>
  );
}

export default App;

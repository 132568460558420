
import { Container } from './styles';
import { usePageContext } from '../../hooks';
import { useSocketContext } from '../../context/SocketContext';
import { useEffect } from 'react';
import QRCode from 'react-qr-code';

export default function Home() {
  const {user} = usePageContext()

  
  const { setIsLogged, setSessionName, setSessionData, sessionData, sessionName } = usePageContext();
  const socketContext = useSocketContext();

  useEffect(() => {
    if (socketContext && socketContext.isConnected) {
      console.log('Socket is connected, listening for events...');

      
      socketContext.onEvent('status', ({ sessionName, payload }: any) => {
        console.log({ sessionName, payload })
        // if(payload.status === 'no-session') return toast.error('Sessão não encontrada');
        // console.log('Session joined event received:', sessionName);
        // setSessionName(sessionName);
        setSessionData({sessionName, payload})
        // setIsLogged(true);
        // navigate('/home');
      });
    }
  }, [socketContext]);


  return (
      <Container>
        <h1>Seja bem-vindo, {user?.student?.name}</h1>
        {sessionData?.payload?.qr && <QRCode value={sessionData?.payload?.qr}/>}
      </Container>
  );
}
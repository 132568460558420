/* eslint-disable jsx-a11y/alt-text */
'use client';

import Button from '../../components/_ui/Button';
import Input from '../../components/_ui/Input';
import { useEffect, useRef } from 'react';
import { Card, Container } from './styles';
import Spacer from '../../components/_ui/Spacer';
import { useNavigate } from 'react-router-dom';
import { usePageContext } from '../../hooks';
import { useSocketContext } from '../../context/SocketContext';
import { toast } from 'react-toastify';

export default function Page() {
  const navigate = useNavigate();
  const { setIsLogged, setSessionName, setSessionData } = usePageContext();
  const enrollmentRef = useRef<HTMLInputElement>(null);

  const socketContext = useSocketContext();

  useEffect(() => {
    if (socketContext && socketContext.isConnected) {
      console.log('Socket is connected, listening for events...');
      socketContext.onEvent('status', ({ sessionName, payload }: any) => {
        if(payload.status === 'no-session') return toast.error('Sessão não encontrada');
        console.log('Session joined event received:', sessionName);
        // socketContext.
        setSessionName(sessionName);
        setSessionData({ sessionName, payload})
        setIsLogged(true);
        navigate('/home');
      });
    }
  }, [socketContext]);

  async function handleSubmit() {
    const enrollment = enrollmentRef.current?.value;

    if (socketContext && socketContext.emitEvent && enrollment) {
      socketContext.emitEvent('join-session', enrollment);
    }
  }


  return (
    <Container>
      <Card>
        <Spacer height={'1em'} />
        <Input type="text" placeholder="Nome de Sessão" name='enrollment' ref={enrollmentRef} />
        <Spacer height={'1em'} />
        <Button buttonType="primary" type="button" onClick={handleSubmit}>Entrar</Button>
      </Card>
    </Container>
  );
}
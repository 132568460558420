import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';

export const useSocket = (url: string) => {
    const socketRef = useRef<Socket | null>(null);
    const [isConnected, setIsConnected] = useState(false); // Estado de conexão

    useEffect(() => {
        // Inicializa o socket apenas uma vez
        socketRef.current = io(url, {
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        socketRef.current.on('connect', () => {
            console.log('Socket connected');
            setIsConnected(true);
        });

        socketRef.current.on('disconnect', () => {
            console.log('Socket disconnected');
            setIsConnected(false);
        });

        socketRef.current.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [url]);

    // Função para emitir eventos
    const emitEvent = (eventName: string, data: any) => {
        if (socketRef.current && isConnected) {
            socketRef.current.emit(eventName, data);
        } else {
            console.error('Socket is not connected.');
        }
    };

    // Função para escutar eventos
    const onEvent = (eventName: string, callback: (data: any) => void) => {
        if (socketRef.current && isConnected) {
            socketRef.current.on(eventName, callback);
        } else {
            console.error('Socket is not connected.');
        }
    };

    return {
        emitEvent,
        onEvent,
        isConnected,
    };
};
